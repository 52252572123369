declare var require: any;
import { Component, OnInit, 
    ChangeDetectorRef,
    ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ChartService } from '../chart.service'
import {QueryService} from '../query.service'
import {DataService} from '../data.service'
import moment from 'moment'
import html2canvas from 'html2canvas';
import { NgxSpinnerService } from 'ngx-spinner';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';

@Component({
  selector: 'app-absenteeism',
  templateUrl: './absenteeism.component.html',
  styleUrls: ['./absenteeism.component.css']
})
export class AbsenteeismComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = "mapChart";
  updateFromOffice = false;
  updateFromDepartment = false;
  updateFromAge = false;
  updateFromGender = false;
  updateFromMap = false;
  updateFromTimeOffType=false
  updateFromLeaveType=false
  updateFromLine=false
  chartCallback;
  headCount=0;
  sickLeave=0; avgSickLeaveCost=0; sickLeaveCost=0;
  totalRemuneration=0;
  meanRemuneration=0;
  genderchartOptions = this.chartService.getChart('pie','Gender','', [],[])
  agechartOptions = this.chartService.getChart('bar','Age', 'Age Chart', [], [])
  timeofftypechartOptions = this.chartService.getChart('column','Time Off Type', 'Age Chart', [], [])
  leavetypechartOptions = this.chartService.getChart('column','Leave Type', 'Age Chart', [], [])
  officechartOptions = this.chartService.getChart('column','Office', 'Office Chart', [], [])
  departmentchartOptions = this.chartService.getChart('column','Department', 'Department', [], [])
  linechartOptions = this.chartService.getChart('line','Split', 'Split', [], [])
  companyList: Array<string>;
  departmentList: Array<string>;
  officeList: Array<string>;
  chosenList: Array<string> = ["Company", "Date", "Gender", "Country", "Age", "Office", "Department", "TimeOffType", "LeaveType"]
  chosenCompany: string=null;
  chosenDate: Date;
  chosenGender: string=null;
  chosenCountry: string=null;
  chosenAge: string=null;
  chosenOffice: string=null;
  chosenDepartment: string=null;
  chosenTimeOffType:string=null;
  chosenLeaveType:string=null;
  countryClicked: Boolean;
  startDate: Date;
  endDate: Date;
  dateString: string;
  isAdmin: boolean;
  numberOfLoadingCharts: number=0;
  minDate:Date = moment('2012-01-01').startOf('year').toDate();
  maxDate:Date = moment().add(2, 'years').endOf('year').toDate();
  selected: {startDate: moment.Moment, endDate: moment.Moment};
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
 

ranges: any = {
  'Today': [moment(), moment()],
  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
}

constructor(private spinner: NgxSpinnerService,private queryService: QueryService, private chartService: ChartService, private dataService: DataService, private changeDetectionRef: ChangeDetectorRef) { 
  const self = this;
}

  ngOnInit() {
      this.spinner.hide();
      DataService.asyncAxiosIntercept(this);
    this.isAdmin=localStorage.getItem('user-role')=='Admin';
      this.startDate=moment().subtract(1, 'year').toDate();
      this.endDate=moment().toDate();
      this.selected={startDate:moment(this.startDate), endDate: moment(this.endDate)}
    this.chosenDate=new Date();
    //Set Date
    this.updateDateFilter(new Date())
    this.getListOf('Courses', 'Company');
    this.getListOf('Head Count', 'Department');
    this.getListOf('Head Count', 'Office');
    this.getChartComponent();
}

private refreshSelections(){
  const keys=DataService.getAllFilterKeys()
  const filters=keys.map( filter => filter.replace("Filter","") );
  this.chosenList.forEach(val=>{
      if(filters.indexOf(val)===-1)
          this['chosen'+val]=null;
  })
}

downloadImage(){
    html2canvas(document.getElementById("report")).then(function(canvas){
        var a = document.createElement('a');
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        a.download = 'absentee_report.jpg';
        a.click();
    });
  }

/** Load chart */
private getChartComponent(){

  this.refreshSelections();
  
  //set other charts
  if(this.chosenGender==null)
      this.getValuesfromAPI('Gender');
  if(this.chosenTimeOffType==null)
      this.getValuesfromAPI('TimeOffType');
  if(this.chosenLeaveType==null)
      this.getValuesfromAPI('LeaveType');    
  if(this.chosenOffice==null)
      this.getValuesfromAPI('Office');
  if(this.chosenDepartment==null)
      this.getValuesfromAPI('Department');
  if(this.chosenAge==null)
      this.getValuesfromAPI('Age');

  //Set summary
  this.queryService.executeSummary('Sick Leave', ['Sick Leave', 'Avg Sick Leave Cost', 'Sick Leave Cost']).then(e=>{
          [this.sickLeave, this.avgSickLeaveCost, this.sickLeaveCost]=e.data.cellset[1].map(el=>parseInt(Number(el.properties.raw).toString()))
      }).catch(e=>{
        [this.sickLeave, this.avgSickLeaveCost, this.sickLeaveCost]=[0,0,0]
      });

   this.queryService.executeMonthlySplitForTwoYearRange('Sick Leave Monthly', 'Sick Leave', this.startDate.getFullYear(), this.endDate.getFullYear()).then(d=>{
        console.log("Sick Split")

        const formattedData=this.dataService.arrangeDataForChart(d.data.cellset, "SickSplit")
        this.updateFromLine=true;
        this['linechartOptions'].xAxis.categories = formattedData[0];
        this.chartService.getGraphStructure(this.linechartOptions, 'Split', "linechartOptions", formattedData[1], this)
        }).catch(e=>{
            console.log("Error in Sick Leave Split");
            console.log(e);
        })
}

/** Make MDX query calls */
private getValuesfromAPI(chartType: string, selectedDimension?: string){
    let dimension='Sick Leave';
    if(selectedDimension!=null)
        dimension=selectedDimension
  this.queryService['executeDimMdx']('Sick Leave',dimension,'headCount', chartType).then(e=>{
      const formattedData=this.dataService.arrangeDataForChart(e.data.cellset)
      this['updateFrom'+chartType]=true;
      this[chartType.toLowerCase()+'chartOptions'].xAxis.categories = ['0'];
      this.chartService.getGraphStructure(this[chartType.toLowerCase()+'chartOptions'], chartType, chartType.toLowerCase()+"chartOptions",[0], this)
      
      // A freaking hack because highcharts wouldn't redraw the last selected chart
      setTimeout(()=>{
          this['updateFrom'+chartType]=true;
          this[chartType.toLowerCase()+'chartOptions'].xAxis.categories = formattedData[0];
          this.chartService.getGraphStructure(this[chartType.toLowerCase()+'chartOptions'], chartType, chartType.toLowerCase()+"chartOptions",formattedData[1], this)
      },200)
      
  }).catch(e=>{
    console.log("Error while processing "+chartType)
    console.log(e)
})
}

/** Get Company and Job Title List */
private getListOf(cube: string, dimension: string){
  const varName=this.dataService.uncapitalizeFirstLetter(dimension.toLowerCase().split(" ").map(e=>this.dataService.capitalizeFirstLetter(e)).join(""));
  if(localStorage.getItem(varName+'List')==null || localStorage.getItem(varName+'List')=='')
      this.queryService.executeDimensionListMdx(cube, dimension).then(e=>{         
          const data=e.data.cellset.filter((e,i)=>i>0 && e[1].value!="#null").map(e=>e[1].value);
          localStorage.setItem(varName+'List', data);
          this[varName+'List']=data;
      })
  else{
      console.log("caches "+varName+'List')
      this[varName+'List']=localStorage.getItem(varName+'List').split(",");
  }
}

/** Reset Button */
public resetAll(){
    this.chosenCompany=
    this.chosenDepartment=this.chosenGender=
    this.chosenOffice=this.chosenCompany=null
    this.startDate=moment().subtract(1, 'year').startOf('year').toDate()
    this.endDate=new Date();
    this.selected={startDate: moment(this.startDate), endDate: moment(this.endDate)}
    this.updateDateFilter(this.chosenDate)
    this.getChartComponent();
}

/** Company and Job Title change fn */
public onChange(dimension){
    let dimensionName=dimension;
    if(dimensionName=="JobTitle"){
      dimensionName="Job Title"
    }

    if(this['chosen'+dimension]!=null){
          
          DataService.addFilter(
              {key:dimension+"Filter", value: "["+dimensionName+"].["+dimensionName+"].["+this['chosen'+dimension]+"]"}
          );
          
          if(DataService.getFilterIndex("CompanyFilter")==-1)
              this.chosenCompany=null;

          
    }
    else{
        const index=DataService.filterVar.findIndex((filterInService)=>filterInService['key']==dimension+"Filter")
        if(index>-1)
          DataService.getFilterVar().splice(index, DataService.getFilterVar().length)
    }
    this.getChartComponent();
}

/** Single date update */
public onDateUpdate(event){
    if(this.selected.startDate!=null && this.selected.endDate!=null){
        console.log(this.selected.startDate+"  "+this.selected.endDate)
        this.updateDateFilter(this.selected.endDate)
        this.getChartComponent();
      }
}

private updateDateFilter(date: any){
      this.chosenCompany=null;
      let sDate = moment(this.selected.startDate)
      let eDate = moment(this.selected.endDate)
      this.dateString = moment(sDate).format("MMM DD, YYYY")+" - "+moment(eDate).format("MMM DD, YYYY")
      let value = "NonEmptyCrossJoin({[Start Date].[DateISO].FirstChild : [Start Date].[DateISO].["+eDate.format('YYYY-MM-DD')+"]}, {[End Date].[DateISO].["+sDate.format('YYYY-MM-DD')+"] : [End Date].[DateISO].LastChild})"
      DataService.setFilter([{key:"DateFilter", value: value}])
}
openDatepicker(){
  this.pickerDirective.open()
}
}
