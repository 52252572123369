declare var require: any;
import { Component, OnInit,
    ChangeDetectorRef} from '@angular/core';
import * as Highcharts from 'highcharts';
import { ChartService } from '../chart.service'
import {QueryService} from '../query.service'
import {DataService} from '../data.service'
import moment from 'moment'
import {GlobalConstants} from '../global-constants';
import html2canvas from 'html2canvas';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import worldMap from "@highcharts/map-collection/custom/world.geo.json";
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-headcount',
  templateUrl: './headcount.component.html',
  styleUrls: ['./headcount.component.css']
})

export class HeadcountComponent implements OnInit {

    color = 'primary';
    mode = 'indeterminate';
    value = 50;
    displayProgressSpinner = false;
    spinnerWithoutBackdrop = false;



    Highcharts: typeof Highcharts = Highcharts;
    chartConstructor = "mapChart";
    updateFromOffice = false;
    updateFromDepartment = false;
    updateFromAge = false;
    updateFromGender = false;
    updateFromMap = false;
    updateFromLine = false;
    chartCallback;
    headCount=0;
    totalRemuneration=0;
    meanRemuneration=0;
    chartOptions = this.chartService.getMap("map", worldMap, "", "headCountMap")
    genderchartOptions = this.chartService.getChart('pie','Gender','', [],[])
    agechartOptions = this.chartService.getChart('column','Age', 'Age Chart', [], [])
    officechartOptions = this.chartService.getChart('column','Office', 'Office Chart', [], [])
    departmentchartOptions = this.chartService.getChart('column','Department', 'Department', [], [])
    linechartOptions = this.chartService.getChart('line','Split', 'Split', [], [])
    companyList: Array<string>;
    officeList: Array<string>;
    departmentList: Array<string>;
    jobTitleList: Array<string>;
    chosenList: Array<string> = ["Company","JobTitle", "Date", "Gender", "Country", "Age", "Office", "Department"]
    chosenCompany: string=null;
    chosenJobTitle: string=null;
    chosenDate: Date;
    chosenGender: string=null;
    chosenCountry: string=null;
    chosenAge: string=null;
    chosenOffice: string=null;
    chosenDepartment: string=null;
    countryClicked: Boolean;
    dateString: string = moment().format("MMM DD, YYYY");
    isAdmin: boolean;
    numberOfLoadingCharts: number=0;
    minDate:Date = moment('2012-01-01').startOf('year').toDate();
    maxDate:Date = moment().add(2, 'years').endOf('year').toDate();

  constructor(private spinner: NgxSpinnerService, private router: Router, private queryService: QueryService, 
                private chartService: ChartService, private dataService: DataService, 
                private changeDetectionRef: ChangeDetectorRef) { 
    const self = this;
  }

  downloadImage(){
    html2canvas(document.getElementById("report")).then(function(canvas){
        var a = document.createElement('a');
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        a.download = 'headcount_report.jpg';
        a.click();
    });
  }

  ngOnInit() {
      this.spinner.hide();
      DataService.asyncAxiosIntercept(this);
      this.isAdmin=localStorage.getItem('user-role')=='Admin';
      this.chosenDate=new Date();
      //Set Date
      this.updateDateFilter(new Date())
      this.getListOf('Courses', 'Company');
      this.getListOf('Head Count', 'Department');
      this.getListOf('Head Count', 'Office');
      this.getListOf('Head Count', 'Job Title');
      this.getChartComponent();
  }

  private refreshSelections(){
    const keys=DataService.getAllFilterKeys()
    const filters=keys.map( filter => filter.replace("Filter","") );
    this.chosenList.forEach(val=>{
        if(filters.indexOf(val)===-1)
            this['chosen'+val]=null;
    })
  }

  /** Load chart */
  private getChartComponent(){

    this.refreshSelections();
    //set map

    if(this.chosenCountry==null)
        this.queryService.executeTerritoryMdx('Head Count','Head Count','headCountTerritory').then(e=>{
            const formattedData=this.dataService.arrangeDataForMap(e.data.cellset)
            this.updateFromMap=true;
            this.chartService.getMapData(this.chartOptions,formattedData, this)
        });
    
    //set other charts
    if(this.chosenGender==null)
        this.getValuesfromAPI('Gender');
    if(this.chosenOffice==null)
        this.getValuesfromAPI('Office');
    if(this.chosenDepartment==null)
        this.getValuesfromAPI('Department');
    if(this.chosenAge==null)
        this.getValuesfromAPI('Age');

    //Set summary
    this.queryService.executeSummary('Head Count', ['Head Count', 'Remuneration', 'Mean Remuneration']).then(e=>{
            [this.headCount, this.totalRemuneration, this.meanRemuneration]=e.data.cellset[1].map(el=>parseInt(Number(el.properties.raw).toString()))
        }).catch(e=>{
            [this.headCount, this.totalRemuneration, this.meanRemuneration]=[0,0,0]
            console.log("-------------------")
            console.log(e.message)
            console.log("----------------------")
            if(e.message.indexOf("failed") > -1 && e.message.indexOf("405")> -1){
                console.log("Not login")
                window.location.href=GlobalConstants.eppioneLink;
                //this.router.navigateByUrl('login');
            }
                
        });

    this.queryService.executeMonthlySplit(this.chosenDate.getFullYear()).then(d=>{
            const formattedData=this.dataService.arrangeDataForChart(d.data.cellset, "HCSplit")
            this.updateFromLine=true;
            this['linechartOptions'].xAxis.categories = formattedData[0];
            this.chartService.getGraphStructure(this.linechartOptions, 'Split', "linechartOptions", formattedData[1], this)
    }).catch(e=>{
        console.log("Error in HC Split");
        console.log(e);
    })

  }

  /** Make MDX query calls */
  private getValuesfromAPI(chartType: string, selectedDimension?: string){

    let dimension='Head Count';
    if(selectedDimension!=null)
        dimension=selectedDimension
    this.queryService['executeDimMdx']('Head Count',dimension,'headCount', chartType).then(e=>{
        const formattedData=this.dataService.arrangeDataForChart(e.data.cellset, chartType)
        this['updateFrom'+chartType]=true;
        this[chartType.toLowerCase()+'chartOptions'].xAxis.categories = [];
        this.chartService.getGraphStructure(this[chartType.toLowerCase()+'chartOptions'], chartType, chartType.toLowerCase()+"chartOptions",[], this)

        // A freaking hack because highcharts wouldn't redraw the last selected chart
        setTimeout(()=>{
            this['updateFrom'+chartType]=true;
            this[chartType.toLowerCase()+'chartOptions'].xAxis.categories = formattedData[0];
            this.chartService.getGraphStructure(this[chartType.toLowerCase()+'chartOptions'], chartType, chartType.toLowerCase()+"chartOptions",formattedData[1], this)
        },200)
        
    }).catch(e=>{
        console.log("Error while processing "+chartType)
        console.log(e)
    })
  }

  /** Get Company and Job Title List */
  private getListOf(cube: string, dimension: string){
    const varName=this.dataService.uncapitalizeFirstLetter(dimension.toLowerCase().split(" ").map(e=>this.dataService.capitalizeFirstLetter(e)).join(""));
    if(localStorage.getItem(varName+'List')==null || localStorage.getItem(varName+'List')=='' || localStorage.getItem(varName+'List')=='null')
        this.queryService.executeDimensionListMdx(cube, dimension).then(e=>{         
            const data=e.data.cellset.filter((e,i)=>i>0 && e[1].value!="#null").map(e=>e[1].value);
            localStorage.setItem(varName+'List', data);
            this[varName+'List']=data;
        })
    else{
        console.log("cached values "+varName+'List')
        this[varName+'List']=localStorage.getItem(varName+'List').split(",");
    }
  }

  /** Reset Button */
  public resetAll(){
      this.chosenCompany=this.chosenJobTitle=
        this.chosenDepartment=this.chosenGender=
        this.chosenOffice=this.chosenCountry=
        this.chosenCompany=null
      this.chosenDate=new Date();
      this.updateDateFilter(this.chosenDate)
      this.getChartComponent();
  }

  /** Company and Job Title change fn */
  public onChange(dimension){
      let dimensionName=dimension;
      if(dimensionName=="JobTitle"){
        dimensionName="Job Title"
      }

      if(this['chosen'+dimension]!=null){
            
            DataService.addFilter(
                {key:dimension+"Filter", value: "["+dimensionName+"].["+dimensionName+"].["+this['chosen'+dimension]+"]"}
            );
            
            if(DataService.getFilterIndex("CompanyFilter")==-1)
                this.chosenCompany=null;
            if(DataService.getFilterIndex("JobTitleFilter")==-1)
                this.chosenJobTitle=null;

            
      }
      else{
          const index=DataService.filterVar.findIndex((filterInService)=>filterInService['key']==dimension+"Filter")
          if(index>-1)
            DataService.getFilterVar().splice(index, DataService.getFilterVar().length)
      }
      this.getChartComponent();
  }

  /** Single date update */
  public onDateUpdate(event: MatDatepickerInputEvent<Date>){
      this.updateDateFilter(this.chosenDate)
      this.getChartComponent();
  }

  private updateDateFilter(date: Date){
        this.chosenCompany=this.chosenJobTitle=null;
        let dateString = moment(this.chosenDate).format('YYYY-MM-DD')
        this.dateString = moment(this.chosenDate).format("MMM DD, YYYY")
        let value = "NonEmptyCrossJoin({[Start Date].[DateISO].FirstChild : [Start Date].[DateISO].["+dateString+"]},\
                {[End Date].[DateISO].["+dateString+"] : [End Date].[DateISO].LastChild})"
        DataService.setFilter([{key:"DateFilter", value: value}])
  }
}
