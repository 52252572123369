declare var require: any;
import { Component, OnInit, ElementRef,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ChartService } from '../chart.service'
import {QueryService} from '../query.service'
import {DataService} from '../data.service'
import moment from 'moment'
import {GlobalConstants} from '../global-constants';
import html2canvas from 'html2canvas';
import worldMap from "@highcharts/map-collection/custom/world.geo.json";
import { NgxSpinnerService } from 'ngx-spinner';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';


@Component({
  selector: 'app-timesheets',
  templateUrl: './timesheets.component.html',
  styleUrls: ['./timesheets.component.css']
})
export class TimesheetsComponent implements OnInit {
    Highcharts: typeof Highcharts = Highcharts;
    chartConstructor = "mapChart";
    updateFromOffice = false;
    updateFromDepartment = false;
    updateFromAge = false;
    updateFromProject = false;
    updateFromMap = false;
    updateFromCompany = false;
    chartCallback;
    workDuration=0; cost=0;
    totalRemuneration=0;
    meanRemuneration=0;
    chartOptions = this.chartService.getMap("map", worldMap, "", "headCountMap")
    officechartOptions = this.chartService.getChart('column','Office', 'Office Chart', [], [])
    departmentchartOptions = this.chartService.getChart('column','Department', 'Department', [], [])
    projectchartOptions = this.chartService.getChart('column','Department', 'Department', [], [])
    companychartOptions = this.chartService.getChart('column','Department', 'Department', [], [])
    companyList: Array<string>;
    officeList: Array<string>;
    departmentList: Array<string>;
    jobTitleList: Array<string>;
    chosenList: Array<string> = ["Company","JobTitle", "Date", "Country", "Office", "Department"]
    chosenCompany: string=null;
    chosenJobTitle: string=null;
    chosenDate: Date;
    chosenProject: string=null;
    chosenCountry: string=null;
    chosenAge: string=null;
    chosenOffice: string=null;
    chosenDepartment: string=null;
    countryClicked: Boolean;
    startDate: Date;
    endDate: Date;
    dateString: string;
    isAdmin: boolean;
    numberOfLoadingCharts: number=0;
    minDate:Date = moment('2012-01-01').startOf('year').toDate();
    maxDate:Date = moment().add(2, 'years').endOf('year').toDate();
    selected: {startDate: moment.Moment, endDate: moment.Moment};
    @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
    ranges: any = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
    }

  constructor(private spinner: NgxSpinnerService,private queryService: QueryService, private chartService: ChartService, private dataService: DataService, private changeDetectionRef: ChangeDetectorRef) { 
    const self = this;
  }

  ngOnInit() {
      this.spinner.hide();
      DataService.asyncAxiosIntercept(this);
      this.isAdmin=localStorage.getItem('user-role')=='Admin';
      this.startDate=moment().subtract(1, 'year').toDate();
      this.endDate=moment().toDate();
      this.selected={startDate:moment(this.startDate), endDate: moment(this.endDate)}
      this.dateString=moment(this.startDate).format("MMM DD, YYYY")+" - "+moment(this.endDate).format("MMM DD, YYYY");
      //Set Date
      this.updateDateFilter(new Date())
      this.getListOf('Courses', 'Company');
      this.getListOf('Head Count', 'Department');
      this.getListOf('Head Count', 'Office');
      this.getChartComponent();
  }

  downloadImage(){
    html2canvas(document.getElementById("report")).then(function(canvas){
        var a = document.createElement('a');
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        a.download = 'timesheets_report.jpg';
        a.click();
    });
  }

  private refreshSelections(){
    const keys=DataService.getAllFilterKeys()
    const filters=keys.map( filter => filter.replace("Filter","") );
    this.chosenList.forEach(val=>{
        if(filters.indexOf(val)===-1)
            this['chosen'+val]=null;
    })
  }

  /** Load chart */
  private getChartComponent(){

    this.refreshSelections();
    //set map

    if(this.chosenCountry==null)
        this.queryService.executeTerritoryMdx('TimeSheet','Work Duration','headCountTerritory').then(e=>{
            const formattedData=this.dataService.arrangeDataForMap(e.data.cellset)
            this.updateFromMap=true;
            this.chartService.getMapData(this.chartOptions,formattedData, this)
        });
    
    //set other charts
    if(this.chosenOffice==null)
        this.getValuesfromAPI('Office');
    if(this.chosenDepartment==null)
        this.getValuesfromAPI('Department');
    if(this.chosenProject==null)
        this.getValuesfromAPI('Project', 'Project Work Duration'); 

    if(this.chosenCompany==null)
        this.getValuesfromAPI('Company');    

    //Set summary
    this.queryService.executeSummary('TimeSheet', ['Work Duration', 'Cost']).then(e=>{
            [this.workDuration, this.cost]=e.data.cellset[1].map(el=>parseInt(Number(el.properties.raw).toString()))
        }).catch(e=>{
            [this.workDuration, this.cost]=[0,0]
            console.log("-------------------")
            console.log(e.message)
            console.log("----------------------")
            if(e.message.indexOf("failed") > -1 && e.message.indexOf("405")> -1){
                console.log("Not login")
                window.location.href=GlobalConstants.eppioneLink;
                //this.router.navigateByUrl('login');
            }
        });
  }

  /** Make MDX query calls */
  private getValuesfromAPI(chartType: string, selectedMeasure?: string){
    let measure='Work Duration';
    if(selectedMeasure!=null)
        measure=selectedMeasure
    this.queryService['executeDimMdx']('TimeSheet',measure,'headCount', chartType).then(e=>{
        const formattedData=this.dataService.arrangeDataForChart(e.data.cellset)
        this['updateFrom'+chartType]=true;
        this[chartType.toLowerCase()+'chartOptions'].xAxis.categories = ['0'];
        this.chartService.getGraphStructure(this[chartType.toLowerCase()+'chartOptions'], chartType, chartType.toLowerCase()+"chartOptions",[0], this)
        
        // A freaking hack because highcharts wouldn't redraw the last selected chart
        setTimeout(()=>{
            this['updateFrom'+chartType]=true;
            this[chartType.toLowerCase()+'chartOptions'].xAxis.categories = formattedData[0];
            this.chartService.getGraphStructure(this[chartType.toLowerCase()+'chartOptions'], chartType, chartType.toLowerCase()+"chartOptions",formattedData[1], this)
        },200)
        
    }).catch(e=>{
        console.log("Error while processing "+chartType)
        console.log(e)
    })
  }

  /** Get Company and Job Title List */
  private getListOf(cube: string, dimension: string){
    this.queryService.executeDimensionListMdx(cube, dimension).then(e=>{
        const varName=this.dataService.uncapitalizeFirstLetter(dimension.toLowerCase().split(" ").map(e=>this.dataService.capitalizeFirstLetter(e)).join(""));
        const data=e.data.cellset.filter((e,i)=>i>0 && e[1].value!="#null").map(e=>e[1].value);
        this[varName+'List']=data;
    })
  }

  /** Reset Button */
  public resetAll(){
      this.chosenCompany=this.chosenJobTitle=
        this.chosenDepartment=this.chosenProject=
        this.chosenOffice=this.chosenCountry=
        this.chosenCompany=null
        this.startDate=moment().subtract(1, 'year').startOf('year').toDate()
        this.endDate=new Date();
        this.selected={startDate: moment(this.startDate), endDate: moment(this.endDate)}
      this.updateDateFilter(this.chosenDate)
      this.getChartComponent();
  }

  /** Company and Job Title change fn */
  public onChange(dimension){
      let dimensionName=dimension;
      if(dimensionName=="JobTitle"){
        dimensionName="Job Title"
      }

      if(this['chosen'+dimension]!=null){
            
            DataService.addFilter(
                {key:dimension+"Filter", value: "["+dimensionName+"].["+dimensionName+"].["+this['chosen'+dimension]+"]"}
            );
            
            if(DataService.getFilterIndex("CompanyFilter")==-1)
                this.chosenCompany=null;
            if(DataService.getFilterIndex("JobTitleFilter")==-1)
                this.chosenJobTitle=null;

            
      }
      else{
          const index=DataService.filterVar.findIndex((filterInService)=>filterInService['key']==dimension+"Filter")
          if(index>-1)
            DataService.getFilterVar().splice(index, DataService.getFilterVar().length)
      }
      this.getChartComponent();
  }

  public onDateUpdate(event){
    if(this.selected.startDate!=null && this.selected.endDate!=null){
        console.log(this.selected.startDate+"  "+this.selected.endDate)
        this.updateDateFilter(this.selected.endDate)
        this.getChartComponent();
      }
}

private updateDateFilter(date: any){
      this.chosenCompany=null;
      let sDate = moment(this.selected.startDate)
      let eDate = moment(this.selected.endDate)
      this.dateString = moment(sDate).format("MMM DD, YYYY")+" - "+moment(eDate).format("MMM DD, YYYY")
      const dateType="Date"
      let value = '[' + dateType + '].[DateISO].[' + sDate.format('YYYY-MM-DD') + ']:[' + dateType + '].[DateISO].[' + eDate.format('YYYY-MM-DD') + ']';
      DataService.setFilter([{key:"DateFilter", value: value}])
}
openDatepicker(){
  this.pickerDirective.open()
}


}
