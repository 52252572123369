import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeadcountComponent } from './headcount/headcount.component';
import { SplitofbenefitComponent } from './splitofbenefit/splitofbenefit.component';
import { AbsenteeismComponent } from './absenteeism/absenteeism.component';
import { TurnoverComponent } from './turnover/turnover.component';
import { TimesheetsComponent } from './timesheets/timesheets.component';
import { TrainingComponent } from './training/training.component';
import { HoliComponent } from './holi/holi.component';
import { ReportssComponent } from './reportss/reportss.component';
import { LoginComponent } from './login/login.component';
import { AnalyticsComponent } from './analytics/analytics.component';
const routes: Routes = [
  { path: 'demographics', component: HeadcountComponent },
  { path: '', component: HeadcountComponent },
  { path: 'employee-benefits', component: SplitofbenefitComponent  },
  { path: 'absenteeism', component:AbsenteeismComponent },
  { path: 'turnover', component:TurnoverComponent },
  { path: 'timesheets', component:TimesheetsComponent },
  { path: 'training', component:TrainingComponent  },
  { path: 'holi', component: HoliComponent  },
  { path: 'reportss', component: ReportssComponent  },
  { path: 'login', component: LoginComponent  },
  { path: 'analytics', component: AnalyticsComponent  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
