import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import {GlobalConstants} from '../global-constants';
import { Router } from '@angular/router';
import { DataService } from '../data.service'
import {CookieService} from 'ngx-cookie-service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

@Component({
  selector: 'app-appmenu',
  templateUrl: './appmenu.component.html',
  styleUrls: ['./appmenu.component.css']
})
export class AppmenuComponent implements OnInit {

  numberOfLoadingCharts:number=0;
  constructor(private router: Router, public sanitizer: DomSanitizer, public cookieService: CookieService) { }

  ngOnInit() {
  }

  goBackToEppione(){
      axios.get(GlobalConstants.apiURL+'api/get-new-key-from-eppione').then(response=>{
        console.log(response.data);
        let baseUrl=this.cookieService.get('redirect_url');
        while(baseUrl.indexOf("\"")>-1){
          baseUrl=baseUrl.replace("\"","");
        }
        const redirectUrl=baseUrl+"/"+response.data;
        console.log("Redirect URL to "+redirectUrl)
        window.location.href=redirectUrl
      })
  }

  logout(){
      axios.post(GlobalConstants.apiURL+"api/logout").then(e=>{
        console.log(e);
        console.log("Logging out")
        let header={headers:{'Authorization': "Bearer "+this.cookieService.get('userToken')}}
        axios.delete(GlobalConstants.eppioneLink+"/eppione/api/authentication/logout/"+this.cookieService.get('userId'),
          header).then(e=>{
            console.log("---------Logged out of Eppione---------")
            console.log(e.data)
            console.log("------------------")
            DataService.removeAllLocalStorageValues();
            window.location.href=GlobalConstants.eppioneLink
          })
        DataService.removeAllLocalStorageValues();
        //this.router.navigateByUrl('login');
      }).catch()
  }

  //getExternalLink:SafeResourceUrl=()=>this.sanitizer.bypassSecurityTrustResourceUrl(GlobalConstants.eppioneLink);

}
