export class GlobalConstants {
    //public static apiURL: string = "http://localhost:4200/";
    //public static analyserURL: string = "http://localhost:9000/";
    //public static eppioneLink: string="https://uk.eppione.com"
    public static apiURL: string = "https://eppione.trivand.com/";
    public static analyserURL: string = "https://eppione.trivand.com/";
    public static eppioneLink: string="https://redesign.eppione.com"
    public static siteTitle: string = "Eppione";

    public static password: string = "admin";
}