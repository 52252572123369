import { Injectable } from '@angular/core';
import { Component } from '@fullcalendar/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  
  static filterVar: Array<Object>;
  static filterKeys: Array<string>;
  
  constructor() { }

  public capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public uncapitalizeFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }

  public arrangeDataForMap(data: Array<Object>){
    try{
      return data
              .filter((e,i)=> i>0 && e[2].value!=null && e[2].value!="")
              .map(e=>[e[1].value.replace('UK', 'GB').toLowerCase(), parseInt(Number(e[2].properties.raw).toString())])
    }
    catch(e){
      return [];
    }
  }

  public arrangeDataForChart(data: Array<Object>, chartType?: string){
    if(data.length==0){
      return [[],[]]
    }
    const dataReceived=data.filter((e,i)=> i>0 && e[1].value!=null && e[1].value!="");
    let formattedData=[];
    for(var i=0;i<dataReceived.length;i++){
      const valueFromAPI=parseInt(Number(dataReceived[i][1].properties.raw).toString());
      formattedData.push([dataReceived[i][0].value, valueFromAPI, valueFromAPI, valueFromAPI])
    }
    return [dataReceived.map(e=>e[0].value),formattedData]
  }

  public static getFilterVar(){
    return DataService.filterVar;
  }

  public static removeAllLocalStorageValues(){
    localStorage.removeItem('user-roles')
    localStorage.removeItem('companyList')
    localStorage.removeItem('officeList')
    localStorage.removeItem('departmentList')
    localStorage.removeItem('jobTitleList')
    localStorage.removeItem('SessionUser');
  }

  public static addFilter(filter: Object){
    let index=DataService.filterVar.findIndex((filterInService)=>filterInService['key']==filter['key'])
    if(index==-1){
      DataService.filterVar.push(filter);
    }
    else{
      DataService.filterVar.splice(index,DataService.filterVar.length)
      DataService.filterVar.push(filter);
    }
  }

  public static setFilter(arr: Array<Object>){
    DataService.filterVar=arr;
  }

  public static getFilterIndex(key: string){
    return DataService.filterVar.findIndex((filterInService)=>filterInService['key']==key)
  }

  public static removeFilter(key: string){
    const index=DataService.filterVar.findIndex((filterInService)=>filterInService['key']==key)
    if(index>-1)
      DataService.filterVar.splice(index,DataService.filterVar.length);
  }

  public static getAllFilterKeys(){
    return DataService.filterVar.map(e=>e['key']);
  }

  public static asyncAxiosIntercept(component: any){
    (async()=>{
        axios.interceptors.request.use( config => {// spinning start to show
          component['numberOfLoadingCharts']++; 
         if(component['numberOfLoadingCharts']>0){
          component['spinner'].show();
         }
         return config
        });
        axios.interceptors.response.use( config => {// spinning stop to show
          component['numberOfLoadingCharts']--;
         if(component['numberOfLoadingCharts']==0){
          component['spinner'].hide();
         }
         return config   
        });
     })();
  }

  public static getWordsWithSpaceFromCamelCase = (s) => s.replace(/([A-Z])/g, ' $1').trim()
}
