import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppheaderComponent } from './appheader/appheader.component';

import { AppmenuComponent } from './appmenu/appmenu.component';
import { SliderComponent } from './slider/slider.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { SplitofbenefitComponent } from './splitofbenefit/splitofbenefit.component';
import { FormsModule } from '@angular/forms';

import { HighchartsChartComponent } from 'highcharts-angular';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as highmaps from 'highcharts/modules/map.src';
import { HeadcountComponent } from './headcount/headcount.component';
import { AbsenteeismComponent } from './absenteeism/absenteeism.component';
import { TurnoverComponent } from './turnover/turnover.component';
import { TimesheetsComponent } from './timesheets/timesheets.component';

import { TrainingComponent } from './training/training.component';
import { HoliComponent } from './holi/holi.component';
import { ReportssComponent } from './reportss/reportss.component';
import { LoginComponent } from './login/login.component';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import { AnalyticsComponent } from './analytics/analytics.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FooterComponent } from './footer/footer.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMM DD, YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    AppheaderComponent,
    
    AppmenuComponent,
    
    SliderComponent,
    
    DropdownComponent,
    
    SplitofbenefitComponent,
    HighchartsChartComponent,
    HeadcountComponent,
    AbsenteeismComponent,
    TurnoverComponent,
    TimesheetsComponent,
    
    TrainingComponent,
    
    HoliComponent,
    
    ReportssComponent,
    
    LoginComponent,
    
    AnalyticsComponent,
    
    FooterComponent,
    
    
    
   
    
   
    
    
  ],
  imports: [
    BrowserModule,BrowserAnimationsModule, FormsModule,
    AppRoutingModule, ChartModule,     MatNativeDateModule,
    MatInputModule, NgxSpinnerModule,
    MatDatepickerModule, NgxDaterangepickerMd.forRoot()
  ],
  providers: [
    //{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {provide: HIGHCHARTS_MODULES, useFactory: () => [ highmaps ]}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
