import { Injectable } from '@angular/core';
import axios from 'axios';
import {GlobalConstants} from './global-constants';
import {DataService} from './data.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  constructor() {
  }

executeTerritoryMdx(cubeName: string, dimension: string, queryName:string){
  const whereFilter='';
  if(DataService.getFilterVar().map(e=>e['key'].replace("Filter","")).indexOf("Project")>-1)
    dimension="Project "+dimension
  const mdxQuery = "WITH \
  MEMBER Measures.ISO as '[Country].[Country].currentmember.properties(\"CountryISO\")' \
  SELECT NON EMPTY {Measures.ISO, [Measures].["+dimension+"]} ON COLUMNS, \
      NON EMPTY FILTER({[Country].[Country].[Country].Members}, [Measures].["+dimension+"]>0) ON ROWS";
  const cube = this.getCube(cubeName);
  const executeMdx = this.buildThinQuery(cube,queryName+Math.random(),mdxQuery,this.whereClause(),true);
  return axios.post(GlobalConstants.apiURL+'rest/saiku/api/query/execute', executeMdx); 
}

executeDimensionListMdx(cubeName: string, dimension:string, subDimension?:string){
  subDimension=subDimension==null?dimension:subDimension;
  const mdxQuery = "SELECT\n" +
  "  { } on 0,\n" +
  "  { DESCENDANTS(["+dimension+"].["+subDimension+"]) } on 1";
  const cube = this.getCube(cubeName);
  const executeMdx = this.buildThinQuery(cube,dimension+Math.random(),mdxQuery,'',true);
  return axios.post(GlobalConstants.apiURL+'rest/saiku/api/query/execute', executeMdx); 
}

executeSummary(cubeName: string, measures: Array<string>){
  if(DataService.getFilterVar().map(e=>e['key'].replace("Filter","")).indexOf("Project")>-1){
    measures=measures.map(e=>"Project "+e)
  }
  const mdxQuery="SELECT\n" +
  "NON EMPTY {"+measures.map(e=>"[Measures].["+e+"]").join()+"} ON COLUMNS\n";
  const cube = this.getCube(cubeName);
  const executeMdx = this.buildThinQuery(cube,'Summary'+Math.random(),mdxQuery,this.whereClause(),true);
  return axios.post(GlobalConstants.apiURL+'rest/saiku/api/query/execute', executeMdx); 
}

executeDimMdx(cubeName: string, measure:string, queryName:string, type: string){
  if(DataService.getFilterVar().map(e=>e['key'].replace("Filter","")).indexOf("Project")>-1 && type!="Project"){
    measure="Project "+measure;
  }
  type=DataService.getWordsWithSpaceFromCamelCase(type)
  if(type=="Age")
    type="Age Group"

  const executeMdx = this.buildThinQuery(
      this.getCube(cubeName),
      type+Math.random(),
      "SELECT NON EMPTY {[Measures].[Measures].["+measure+"]} ON COLUMNS,\n" +
        "NON EMPTY TopCount({["+type+"].["+type+"].["+type+"].Members},10,[Measures].[Measures].["+measure+"]) ON ROWS",
        this.whereClause(),true
    );
  return axios.post(GlobalConstants.apiURL+'rest/saiku/api/query/execute', executeMdx); 
}

executeMonthlySplit(year){
  let mdx="SELECT NON EMPTY {[Measures].[Head Count]} ON COLUMNS,\
    NON EMPTY {[Month Date].[Monthly].[Month Year].Members}ON ROWS" 
  
  const executeMdx = this.buildThinQuery(
      this.getCube('Head Count Monthly'),
      'HeadCountMonthly'+Math.random(),
      mdx,
      this.whereClauseForHCEvolution(year),true
    );
  return axios.post(GlobalConstants.apiURL+'rest/saiku/api/query/execute', executeMdx);
}

executeMonthlySplitForTwoYearRange(cube, dimension, year1, year2){
    const mdx="SELECT NON EMPTY {[Measures].["+dimension+"]} ON COLUMNS,\
             NON EMPTY {[Month Date].[Monthly].[Month Year].Members} ON ROWS ";
    const executeMdx = this.buildThinQuery(
              this.getCube(cube),
              'AbsenteeismMonthly'+Math.random(),
              mdx,
              this.whereClauseForAnnualLeaveEvolution(year1, year2),true
            );
    return axios.post(GlobalConstants.apiURL+'rest/saiku/api/query/execute', executeMdx);        
}

executeTurnoverMonthlySplit(date1: Date, date2: Date){
  const year1=moment(date1).year()
  const year2=moment(date2).year()
  const mdx=this.getMonthlySegments(this.getAllNumbersBetween(year1,year2),
            [moment(date1).format('YYYY-MM-DD'), moment(date2).format('YYYY-MM-DD')])
  const executeMdx = this.buildThinQuery(
    this.getCube('Turnover'),
    'Turnover Split Monthly'+Math.random(),
    mdx,
    this.whereClauseWithNoDate(),true
  );
  return axios.post(GlobalConstants.apiURL+'rest/saiku/api/query/execute', executeMdx);  
}

getCube(cubeName: String){
  return {
    uniqueName:'[EppioneConnect].[Eppione].[Eppione].['+cubeName+']',
    name: cubeName, 
    caption:null, 
    catalog:"Eppione", 
    connection:'EppioneConnect',
    schema:'Eppione',
    visible:false};
  
}
buildThinQuery(cube, queryName, query, filterClause, showParents){
    return {
        "queryModel": {
            axes:{},
            calculatedMeasures:[],
            calculatedMembers:[],
            details:null,
            lowestLevelsOnly: false,
            visualTotals: false,
            visualTotalsPattern: null
        },
        "cube": cube,
        "mdx": query + ' FROM ['+cube.name+'] '+filterClause,
        "name": queryName,
        "parameters": {},
        "plugins": {},
        "properties": this.includeParents(showParents),
        "metadata": {},
        "queryType": "OLAP",
        "type": "MDX"
    };
}

includeParents(showParents) {
    if (showParents) {
        return {
            "saiku.olap.result.formatter": "flat",
            "saiku.web.export.csv.name": "abc"
        };
    }
    else {
        return { "saiku.web.export.csv.name": "abc"};
    }
}

  private whereClause(){
    return "WHERE ({"+DataService.getFilterVar().map(obj=>{
        return obj['value'];
      }).join("} * {")+"})";
  }

  private whereClauseWithNoDate(){
    let whereCondition="WHERE ({"+DataService.getFilterVar().filter(obj=>obj['key']!="DateFilter").map(obj=>{
      return obj['value'];
    }).join("} * {")+"})";
    if(whereCondition=="WHERE ({})")
      whereCondition="";
    return whereCondition;
  }

  private whereClauseForHCEvolution(year){
    return "WHERE ({"+DataService.getFilterVar().map(obj=>{
        if(obj['key']==="DateFilter")
          return "{{[Month Date].[Quarterly].[2012]:[Month Date].[Quarterly].["+year+"]}}"
        return obj['value'];
      }).join("} * {")+"})";
  }

  private whereClauseForAnnualLeaveEvolution(year1, year2){
    return "WHERE ({"+DataService.getFilterVar().map(obj=>{
      if(obj['key']==="DateFilter")
        return "{{[Month Date].[Quarterly].["+year1+"]:[Month Date].[Quarterly].["+year2+"]}}"
      return obj['value'];
    }).join("} * {")+"})";
  }

  public getMonthlySegments(years, dateBoundary){
    var breakLoop=false;
    var text="WITH ";
    var selectValues=[];
    for(var i=0;i<years.length;i++){
        var j=0;
        while(j<12){
            var date=new Date(years[i], j ,1);
            var momentObj=moment(date);
            if(momentObj<moment(dateBoundary[0],"YYYY-MM-DD") || momentObj>moment(dateBoundary[1],"YYYY-MM-DD")){
                j++;
                continue;
            }

            if(moment()<momentObj) {
                breakLoop = true;
                break;
            }
            selectValues.push("[Start Date].[DateISO].["+momentObj.format('YYYY')+"-"+momentObj.format('MMM') +"]")
            text+=" MEMBER [Start Date].[DateISO].["+momentObj.format('YYYY')+"-"+momentObj.format('MMM') +"] AS AGGREGATE({[End Date].[DateISO].["+momentObj.format('YYYY-MM-DD')+ "] : [End Date].[DateISO].["+momentObj.endOf('month').format('YYYY-MM-DD')+"]})"
            j++;
        }
        if(breakLoop)
            break;
    }

   return text+" SELECT NON EMPTY {[Measures].[Head Count]} ON COLUMNS, NON EMPTY {"+selectValues.join()+"} ON ROWS";
  }

  private getAllNumbersBetween(lowEnd, highEnd){
    let list = [];
    for (var i = lowEnd; i <= highEnd; i++) {
        list.push(i);
    }
    return list;
  }
}