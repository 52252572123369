import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '../global-constants';
import {Router} from '@angular/router'
import axios from 'axios';
import {CookieService} from 'ngx-cookie-service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public username:string
  password:string
  rememberMe=false
  cookieValue=''
  constructor(private router: Router, private cookieService: CookieService) {
    this.cookieValue = this.cookieService.get('JSESSIONID');
  }

  ngOnInit() {
    console.log("In login")
    if(this.username!=null)
        axios.get(GlobalConstants.apiURL+'api/users/'+this.username).then(e=>{
          localStorage.setItem('SessionUser', e.data);
          this.router.navigateByUrl('demographics');
        }).catch(e=>{
          console.log("Not logged in");
          DataService.removeAllLocalStorageValues();
        })
    else
        DataService.removeAllLocalStorageValues();
  }

  login(){
    console.log(this.username+"  "+this.password)
    var data = 'j_username=' + encodeURIComponent(this.username) +
                    '&j_password=' + encodeURIComponent(this.password) +
                    '&remember-me=' + this.rememberMe + '&submit=Login';
    axios.post(GlobalConstants.apiURL+'api/authentication', data,{
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
      },withCredentials:true
    }).then(r=>{
        console.log(r.request)
        console.log(this.cookieService.getAll());
        axios.get(GlobalConstants.apiURL+'api/users/'+this.username).then(e=>{
          console.log(e.data)
          localStorage.setItem('user-role', e.data.roles.map(r=>r.description).indexOf('Admin')>-1?'Admin':'User');
          localStorage.setItem('SessionUser', e.data.login);
          this.router.navigateByUrl('demographics');
        })
        
      })
  }

}
