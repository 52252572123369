import { Component, OnInit, PipeTransform, Pipe } from '@angular/core';
import {GlobalConstants} from '../global-constants'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit {
  myVar: boolean=false;
  url: string=GlobalConstants.analyserURL+"analyser/index.html?splash=false#";
  urlSafe: SafeResourceUrl;
  arrow: string= ">>"
  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit(): void {

  }
  public getUrl(): SafeResourceUrl{
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  public showTab(){
    if(this.arrow===">>")
      this.arrow="<<"
    else
      this.arrow=">>"
    this.myVar=!this.myVar;
  }

}
