import { Injectable } from '@angular/core';
import {DataService} from './data.service'

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  showSynonym=false;
  constructor() { }
  
  public getMap=(type, mapType, title, chartName)=>{
    return {
        credits:{enabled: false},
        chart: {
          map: mapType as any,
          name: chartName,
          height: 262
        },
        title: {
          text: title
        },
        mapNavigation: {
          enabled: true,
          enableMouseWheelZoom: false,
          enableTouchZoom: false,
          enableDoubleClickZoom: false,
          buttonOptions: {
            theme: {
                fill: 'white',
                    'stroke-width': 1,
                    stroke: 'silver',
                    r: 0,
                    states: {
                    hover: {
                        fill: '#F3F3F3'
                    },
                    select: {
                        fill: '#c9c9c9'
                    }
                }
            },
            verticalAlign: 'bottom'
        }
        },
        legend: {
          enabled: false
        },
        colorAxis: {
          min: 0,
          minColor: '#56A0F4',
          maxColor: '#56A0F4'
        },
        series: [
          {
            type: type,
            name: "MapChart",
            dataLabels: {
              enabled: false,
              format: "{point.name}"
            },
            allAreas: true,
            data: [],
            allowPointSelect: true,
            cursor: 'pointer',
            states: {
                select: {
                    color: '#3A7CA5',
                    borderColor: 'black',
                    dashStyle: 'shortdot'
                    }
            }
          }
        ]
      };
  }

  public getChart=(type, title,chartName, xValues, data)=>{
    return {
        colors:['#3A7CA5'],
        credits: {
            enabled: false
        },
      chart: {
          className: chartName,
          renderTo: 'container',
          type: type,
          height: 262
      },
      title: {
          text: '',//title,
          align: 'left',
          style: {
    
              fontSize: 14,
              fontWeight: 'bold'
          }
      },
      tooltip: {
        followPointer:true,
        hideDelay:100,
        style: {

            fontSize: '9px'
        },
     formatter: function () {
          return getToolTip(this);
     },
     positioner: function(labelWidth, boxHeight, point) {
         var tooltipX, tooltipY;
         if (point.plotX + labelWidth > this.chart.plotWidth) {
             tooltipX = point.plotX + this.chart.plotLeft - labelWidth - 20;
         } else {
             tooltipX = point.plotX + this.chart.plotLeft + 20;
         }
         tooltipY = point.plotY + this.chart.plotTop - 20;
         return {
             x: tooltipX,
             y: tooltipY
         };
        }
     },
    
    
      xAxis: {
          categories: xValues,
          type: '',
          ordinal: false,
          labels: {
            enabled: true,
            formatter: function() {
                    return this.value;
            }
        },
    
    
      },
      yAxis: {
        labels: {
            enabled: true,
            formatter: function () {
                var result = this.value;
                return result;
            }
          },
          style: {
              fontSize: '10px'
          },
          title: {
              style: {
                  fontSize: '0px'
              }
          }
      },
      plotOptions: {
        series: {
            states:{
                inactive:{opacity:0.5}
            },
            stacking: 'normal',
            cursor: 'pointer',
            point: {
              events: {
                click: function(Event){
                    if(this.state!=='select')
                    console.log('Name: '+ this.category + ', Value: ' + this.y + ', Series :' + this.series.name);
                }
              }
            },
            showInLegend: false
          },
          pie: {
            slicedOffset: 20,
            center: ["50%", "50%"],
            showInLegend: true,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: false,
            },
            shadow: false,
            size: '100%',
            innerSize: '40%'
        }
      },
      series: [{
          allowPointSelect: true,
          states: {
              select: {
                  color: '#3A7CA5',
                  borderColor: 'black',
                      dashStyle: 'shortdot'
              }
    
          },
          name: chartName,
          data: data
      }],
    
    };
  }

  public getMapData(obj, data: Array<Object>, component){
    obj.plotOptions={
        series: {
            point: {
                events: {
                    click: function (event) {
                        let damageFilterName="Country"
                        if (this.state === 'select') {
                            DataService.removeFilter(damageFilterName+"Filter");
                            component['chosen'+damageFilterName]=null;
                        }
                        else {
                            let filter={key:damageFilterName+"Filter", value:"["+damageFilterName+"].["+damageFilterName+"].["+this.name+"]"}
                            DataService.addFilter(filter);
                            component['chosen'+damageFilterName]=this.name;
                        }
                        component.getChartComponent();
                    }
                }
            }
        }
    };

    obj.series[0].data=data;
  }

  public getGraphStructure(obj, damageFilterName, damageType, data, component){
    obj.plotOptions={
        series: {
            point: {
                events: {
                    
                    click: function (event) {
                        if(damageFilterName!='' && this.shapeType!=null){
                            if (this.state === 'select') {
                                DataService.removeFilter(damageFilterName+"Filter");
                                component['chosen'+damageFilterName]=null;
                                
                                for (var i = 0; i < this.series.data.length; i++) {
                                    this.series.data[i].update({color: '#3A7CA5'}, true, false);
                                }
                            }
                            else {
                                console.log(damageFilterName)
                                let dimName=damageFilterName;
                                if(dimName=="Age")
                                    dimName="Age Group"
                                else if(dimName=="BenefitType")
                                    dimName="Benefit Type"
                                else if(dimName=="TimeOffType")
                                    dimName="Time Off Type";
                                else if(dimName=="LeaveType")
                                    dimName="Leave Type";
                                let filter={key:damageFilterName+"Filter", value:"["+dimName+"].["+dimName+"].["+this.name+"]"}
                                DataService.addFilter(filter);
                                component['chosen'+damageFilterName]=this.name;
                                for (var i = 0; i < this.series.data.length; i++) {
                                    this.series.data[i].update({color: '#cbcbcb'}, true, false);
                                }
                            }
                            component.getChartComponent();
                        }
                        
                    }//.bind(self)
                }
            },
            cursor: 'pointer',
            allowPointSelect: true,
            slicedOffset: 0,
            states: {
                select: {
                    color: '#3A7CA5'
                }
            },
            dataLabels: {
                crop: false,
                allowOverlap: true,
                overflow: 'none',
                style: {
                    fontSize: '8px'
                },
                formatter: function () {
                    /*if(obj.chart.type!="pie")
                        return Highcharts.numberFormat(this.y,0,'',',');
                    else
                        return this.key;*/
                },
                enabled: true
            },
            showInLegend: false
        },
        pie: {
            dataLabels: {
                enabled: true,
                alignTo: 'connectors',
                overflow: 'justify',
                color: 'black',
                format: '{point.name}',
                style: {
                  textOutline: false ,
                  fontSize:'10px'
              }
            },
            innerSize: '30%',
            shadow: false,
            center: ['50%', '50%']
        },
        bubble:{
            minSize:'10%',
            maxSize:'15%'
        }
    };
    obj.series[0].data=data;
  }
}


function getToolTip(obj) {
    return obj.key + '<br/>' + '<b> ' + obj.y;
}