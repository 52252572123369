import { Component, OnInit } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  text="Headcount";
  constructor(private router: Router) { 
    
    if(this.router.url=== '/employee-benefits')
      this.text="Benefits"
    if(this.router.url=== '/training')
      this.text="Approved Training"
    if(this.router.url=== '/timesheets')
      this.text="Timesheets"
    if(this.router.url=== '/timesheets')
      this.text="Timesheets"
    if(this.router.url=== '/holi')
      this.text="Annual Leave"
    if(this.router.url=== '/absenteeism')
      this.text="Absenteeism"
    if(this.router.url=== '/turnover')
      this.text="Turnover"
  }

  ngOnInit() {
    
  }

}
